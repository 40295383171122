import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt" 


export const getTopics = createAsyncThunk('getTopics', async (query, { rejectWithValue }) => {
    try {
        const res = await useJwt.getTopics(query)
        const data = res?.data || {}  
        return { data }
    } catch (error) { 
        if (error.response && error.response.data) { 
            return rejectWithValue(error.response.data)  
        } else {
            return rejectWithValue({ message: 'Something went wrong' })
        }
    }
})


export const getSessions = createAsyncThunk('getSessions', async (payload, { rejectWithValue }) => {
    try {
        const res = await useJwt.getSessions(payload.topicId, `?limit=${payload.perPage}&page=${payload.page}&search=${payload.search || ''}`)
        return {data : res.data}
    } catch (error) { 
        if (error.response && error.response.data) { 
            return rejectWithValue(error.response.data)  
        } else {
            return rejectWithValue({ message: 'Something went wrong' })
        }
    }

})

export const getAllSessions = createAsyncThunk('getAllSessions', async (query, { rejectWithValue }) => {
    try { 
        const res = await useJwt.getAllSessions(query)
        return {data : res.data}
    } catch (error) { 
        if (error.response && error.response.data) { 
            return rejectWithValue(error.response.data)  
        } else {
            return rejectWithValue({ message: 'Something went wrong' })
        }
    }

})

const LearningCentre = createSlice({
    name: 'learningCentre',
    initialState: { 
        allSessions: {
            loading : true,
            data : {},
            error : ''
        },
        sessions: {
            loading : true,
            data : {},
            error : ''
        },
        topics : {
            loading : false,
            data : {},
            error : ''
        }
    }, 
    extraReducers: {
        [getSessions.fulfilled]: (state, action) => {
            state.sessions.data = action.payload.data
            state.sessions.loading = false
            state.sessions.error = ''
        },
        [getSessions.pending]: (state) => {
            state.sessions.loading = true
            state.sessions.error = ''
        },
        [getSessions.rejected]: (state, action) => {
            state.sessions.loading = false
            state.sessions.error = action.payload
        },
        [getAllSessions.fulfilled]: (state, action) => {
            state.allSessions.data = action.payload.data
            state.allSessions.loading = false
            state.allSessions.error = ''
        },
        [getAllSessions.pending]: (state) => {
            state.allSessions.loading = true
            state.allSessions.error = ''
        },
        [getAllSessions.rejected]: (state, action) => {
            state.allSessions.loading = false
            state.allSessions.error = action.payload
        },
        [getTopics.fulfilled]: (state, action) => {
            state.topics.data = action.payload.data
            state.topics.loading = false
            state.topics.error = '' 
        },
        [getTopics.pending]: (state) => {
            state.topics.loading = true
            state.topics.error = '' 
        },
        [getTopics.rejected]: (state, action) => {
            state.topics.loading = false
            state.topics.error = action.payload
        }
    }
})
 
export default LearningCentre.reducer

